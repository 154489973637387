import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import { useConfirm } from "material-ui-confirm";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { ActionContext } from "../../../contexts";
import { Button } from "../../shared/Button";
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import {Typography} from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    width: 300,
    height: 200,
  },
  cardHeader: {
    boxShadow: `3px 3px 8px 0px ${theme.colors.veryLightGrey}`,
    color: theme.colors.primaryNavy,
    fontFamily: theme.fontFamily.heading,
    height: 50,
  },
  avatar: {
    backgroundColor: theme.colors.primaryNavy,
    height: 32,
    width: 32,
    boxShadow: `3px 3px 8px 0px ${theme.colors.veryLightGrey}`,
  },
  pdfIcon: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 50
  },
  unLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.robotoCondensed,
    '&:hover': {
      color: theme.palette.text.secondary,
    }
  },
}));

let ConsentFormCard = (props) => {
  let classes = useStyles();
  let confirm = useConfirm();
  let { consentFormId, consentForm, linkingConsentForm } = props;
  let { consentActions } = useContext(ActionContext);

  let handleUnlinkConsentForm = () => {
    confirm({
      description: "Do you really want to unlink the consent form?",
      confirmationText: "Yes",
    })
      .then(() => {
        let data = {
          candidate_id: consentForm.playerId,
        };
        consentActions.unlinkConsentForm(data, consentFormId);
      })
      .catch(() => {
        // do nothing
      });
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
          className={classes.cardHeader}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar} src={null} />
        }
        title={consentForm.name}
        titleTypographyProps={{ variant: "subtitle2" }}
        subheaderTypographyProps={{ variant: "caption" }}
      />
      <Typography className={classes.pdfIcon}>
        <PictureAsPdfOutlinedIcon fontSize='inherit'/>
      </Typography>
      <CardActions>
        <Button
          className={classes.unLink}
          variant="text"
          onClick={handleUnlinkConsentForm}
          loading={linkingConsentForm}
        >
          Unlink
        </Button>
      </CardActions>
    </Card>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { consentFormId } = ownProps;
  return {
    consentForm: state.consent.linkedConsentForms.byId[consentFormId],
    linkingConsentForm: state.ui.consent.linkingConsentForm[consentFormId],
  };
};
export default connect(mapStateToProps)(ConsentFormCard);
