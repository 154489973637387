import apiHandler, {getAuthHeader} from "./apiHandler";


export const getAllSports = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get("/sports", { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.sports;
  } catch (err) {
    throw new Error(err);
  }
};

const getSportById = async (sportId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/sport/${sportId}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.sport;
  } catch (err) {
    throw new Error(err);
  }
};

export const getPositionDetails = async (sportId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/sports_positions/${sportId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.positions;
  } catch (err) {
    throw new Error(err);
  }
};

export const getPositionById = async (id) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/sports_position/${id}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.position;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTeamUsers = async (team) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/load-team-users/${team}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.accounts;
  } catch (err) {
    throw new Error(err);
  }
};

export const createSport = async (name) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post('/sport', name, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.sports;
  } catch (err) {
    throw new Error(err)
  }
};

export const updateSport = async (sportId, name) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.put(`/sport/${sportId}`, name, {headers});

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.sports;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAllSportsUnits = async () => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get('/units', { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.units;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUnitsBySportId = async (sportId) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.get(`/sport_units/${sportId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.units;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUnitById = async (unitId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/unit/${unitId}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.unit;
  } catch (err) {
    throw new Error(err);
  }
};

export const createUnit = async (unit) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post('/unit', unit, { headers });

    if (response.data.status !== 'Success') {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateUnit = async (unitId, unit) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.put(`/unit/${unitId}`, unit, { headers });

    if (response.data.status !== 'Success') {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.unit;
  } catch (err) {
    throw new Error(err);
  }
};

export const createPosition = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post('/position', data,{ headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.positions;
  } catch (err) {
    throw new Error(err);
  }
};

export const updatePosition = async (id, data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.put(`/position/${id}`, data, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.positions;
  } catch (err) {
    throw new Error(err);
  }
};

export const deletePosition = async (id) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.delete(`/position/${id}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.positions;
  } catch (err) {
    throw new Error(err);
  }
};

const sports = {
  getAllSports,
  getSportById,
  getPositionDetails,
  getPositionById,
  getTeamUsers,
  createSport,
  updateSport,
  getAllSportsUnits,
  getUnitsBySportId,
  getUnitById,
  createUnit,
  updateUnit,
  createPosition,
  updatePosition,
  deletePosition,
};

export default sports;