import React, { useState } from "react";
import ExpansionArticle from "../ExpansionArticle";
import { connect } from "react-redux";
import { Box, makeStyles, Typography } from "@material-ui/core";
import {ChildTab, MainTab} from "../../../shared/Tabs";
import ChartAndDetails from "../../../athlet-management/components/ChartAndDetails";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    flexGrow: 1,
    display: "flex",
    margin: "0px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "3px 3px 10px 3px #cdcdcd",
    borderRadius: 8,
  },
  sideBarTitle: {
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
  },
  selectedSideBarTitle: {
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    backgroundColor: theme.colors.veryLightGrey,
  },
  tabPanel: {
    width: '85%',
    padding: '2rem 1rem',
    borderRadius: "0px 8px 8px 0px",
  },
  content: {
    backgroundColor: theme.colors.veryLightGrey,
    width: '90%',
    alignSelf: 'center',
    marginBottom: '1rem',
    borderRadius: 5,
  },
  brain: {
    fontFamily: theme.fontFamily.heading,
    fontSize: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0rem 2rem",
    width: "auto",
    height: "20vh",
    color: theme.palette.primary.main,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const BeyondBig5 = (props) => {
  let classes = useStyles();
  let { big5, basicInfo } = props;

  const [selectedPage, setSelectedPage] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedPage(newValue);
  };

  return (
      <div className={classes.root}>
        {basicInfo && basicInfo.brain === "Receptiviti" ? (
            <>
              <Box className={classes.container}>
                <MainTab
                    orientation="vertical"
                    variant="scrollable"
                    value={selectedPage}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    style={{ width: 250 }}
                >
                  <ChildTab
                      className={selectedPage === 0 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                      label="Natural Leadership"
                      {...a11yProps(0)}
                  />
                  <ChildTab
                      className={selectedPage === 1 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                      label="Person Centered"
                      {...a11yProps(1)}
                  />
                  <ChildTab
                      className={selectedPage === 2 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                      label="Processing Power"
                      {...a11yProps(2)}
                  />
                  <ChildTab
                      className={selectedPage === 3 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                      label="Reward"
                      {...a11yProps(3)}
                  />
                  <ChildTab
                      className={selectedPage === 4 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                      label="Agentic Disposition"
                      {...a11yProps(4)}
                  />
                  <ChildTab
                      className={selectedPage === 5 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                      label="Communal Disposition"
                      {...a11yProps(5)}
                  />
                  <ChildTab
                      className={selectedPage === 6 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                      label="Authentic"
                      {...a11yProps(6)}
                  />
                  <ChildTab
                      className={selectedPage === 7 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                      label="Clout"
                      {...a11yProps(7)}
                  />
                  <ChildTab
                      className={selectedPage === 8 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                      label="Challenge"
                      {...a11yProps(8)}
                  />
                  <ChildTab
                      className={selectedPage === 9 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                      label="Stability"
                      {...a11yProps(9)}
                  />
                </MainTab>
                <TabPanel
                    value={selectedPage}
                    index={0}
                    className={classes.tabPanel}
                >
                  <div>
                    <ExpansionArticle
                        title="Natural Leadership"
                        details="This measures the degree to which one is assertive, fast-paced or sociable. High scorers exhibit a charasmatic leader style."
                    >
                      <ChartAndDetails
                          score={big5["natural_leadership"]["score"]}
                          tabName="BeyondBig5"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>
                <TabPanel
                    value={selectedPage}
                    index={1}
                    className={classes.tabPanel}
                >
                  <div>
                    <ExpansionArticle
                        title="Person Centered"
                        details="This measures how  warm, supportive, and inquisitive one is about others’ lives. It highlights being agreeable, joking, polite, and interested in conversations."
                    >
                      <ChartAndDetails
                          score={big5["person-centered"]["score"]}
                          tabName="BeyondBig5"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>
                <TabPanel
                    value={selectedPage}
                    index={2}
                    className={classes.tabPanel}
                >
                  <div>
                    <ExpansionArticle
                        title="Processing Power"
                        details="This measures one's ability to pay attention, and/or to process the environment and world around them."
                    >
                      <ChartAndDetails
                          score={big5["processing_power"]["score"]}
                          tabName="BeyondBig5"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>

                <TabPanel
                    value={selectedPage}
                    index={3}
                    className={classes.tabPanel}
                >
                  <div>
                    <ExpansionArticle
                        title="Reward"
                        details="This measures the degree to which a person is driven by an internal need for reward."
                    >
                      <ChartAndDetails
                          score={big5["reward-seeking"]["score"]}
                          tabName="BeyondBig5"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>
                <TabPanel
                    value={selectedPage}
                    index={4}
                    className={classes.tabPanel}
                >
                  <div>
                    <ExpansionArticle
                        title="Agentic Disposition"
                        details="This measures how much one is willing to exert willpower to pursue personal goals. Indicates doing things as an individual for personal motivations or desires."
                    >
                      <ChartAndDetails
                          score={big5["individual_willpower"]["score"]}
                          tabName="BeyondBig5"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>
                <TabPanel
                    value={selectedPage}
                    index={5}
                    className={classes.tabPanel}
                >
                  <div>
                    <ExpansionArticle
                        title="Communal Disposition"
                        details="This measures if a person is cooperating and connecting with others to improve social relationships. Indicates they are likely doing things with other people to help meet the group's goals."
                    >
                      <ChartAndDetails
                          score={big5["communal"]["score"]}
                          tabName="BeyondBig5"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>
                <TabPanel
                    value={selectedPage}
                    index={6}
                    className={classes.tabPanel}
                >
                  <div>
                    <ExpansionArticle
                        title="Authentic"
                        details="This measures the degree to which communication style is personal, honest and unguarded or more closed, guarded and distanced."
                    >
                      <ChartAndDetails
                          score={big5["authenticity"]["score"]}
                          tabName="BeyondBig5"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>
                <TabPanel
                    value={selectedPage}
                    index={7}
                    className={classes.tabPanel}
                >
                  <div>
                    <ExpansionArticle
                        title="Clout"
                        details="This measures the degree to which communication reflects certainty and confidence."
                    >
                      <ChartAndDetails
                          score={big5["clout"]["score"]}
                          tabName="BeyondBig5"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>

                <TabPanel
                    value={selectedPage}
                    index={8}
                    className={classes.tabPanel}
                >
                  <div>
                    <ExpansionArticle
                        title="Challenge"
                        details="This measures ones ability to overcome obstacles and achieve difficult things."
                    >
                      <ChartAndDetails
                          score={big5["challenge"]["score"]}
                          tabName="BeyondBig5"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>
                <TabPanel
                    value={selectedPage}
                    index={9}
                    className={classes.tabPanel}
                >
                  <div>
                    <ExpansionArticle
                        title="Stability"
                        details="This measures one's preference for prioritizing consistency in their environment and avoiding uncertainty or disruptive change."
                    >
                      <ChartAndDetails
                          score={big5["stability"]["score"]}
                          tabName="BeyondBig5"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>
              </Box>
            </>
        ) : (
            <Box className={classes.container}>
              <Typography className={classes.brain}>
                This report is outdated. To see enhanced features, please contact
                your Mental Metrix representative.
              </Typography>
            </Box>
        )}
      </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { coachId } = ownProps;

  return {
    customTraits: state.coach.customTraits[coachId],
    team: state.account.team,
    basicInfo: state.coach.basic[coachId],
    big5: state.coach.deepProfile[coachId],
  };
};

export default connect(mapStateToProps)(BeyondBig5);
