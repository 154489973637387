import {connect} from "react-redux";
import {Container, DeleteButtonModal, FormEditor, RunDashedLogo, Title} from "../../shared";
import {ActionContext} from "../../../contexts";
import React, {useContext, useEffect, useState} from "react";
import useStatusColor from "../../../utils/useStatusSelector";
import {Link, useHistory, useParams} from "react-router-dom";
import {snackbarActions} from "../../shared/Snackbar";
import {Box, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import {number, object, string} from "yup";
import {GlobalCss} from "../../../config/GlobalCss";
import Alert from "@material-ui/lab/Alert";
import {Select, TextField} from "formik-material-ui";
import clsx from "clsx";
import Button from "../../shared/Button";
import CircularProgress from "../../shared/CircularProgress";
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import useStyles from "./styles";
import IconAndLabels from "../../shared/IconAndLabels";
import {round2Decimals} from "../../../utils";

const UpdateCommentAttribute = (props) => {
    const classes = useStyles();
    const {assessmentCommentActions} = useContext(ActionContext);
    const { getColorName ,getHexValue } = useStatusColor();
    const history = useHistory();
    const { attribute, error, loading } = props;
    const {attributeId} = useParams();
    let [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createEmpty());
    let [actionEditorState, setActionEditorState] = useState(EditorState.createEmpty());
    let [labels, setLabels] = useState([]);
    let [iconString, setIconString] = useState('');

    useEffect(() => {
        assessmentCommentActions.getCommentAttributeById(attributeId)
    }, []);

    useEffect(() => {
        if (attribute.description && attribute.action_statement) {
            try {
                const descriptionMarkToDraft = markdownToDraft(attribute.description);
                const descriptionContentState = convertFromRaw(descriptionMarkToDraft);
                setDescriptionEditorState(EditorState.createWithContent(descriptionContentState));

                const actionMarkToDraft = markdownToDraft(attribute.action_statement);
                const actionContentState = convertFromRaw(actionMarkToDraft);
                setActionEditorState(EditorState.createWithContent(actionContentState));
            } catch (error) {
                throw new Error(error);
            }
        }
        setLabels(attribute.labels);
        if (attribute.icon) {
            setIconString(attribute.icon);
        }
    }, [attribute]);

    let handleErrorClose = () => {
        assessmentCommentActions.resetCommentAttributeError();
    };

    useEffect(() => {
        if (error && error.length > 0) {
            setTimeout(() => {
                handleErrorClose();
            }, 3500);
        }
    }, [error]);

    const colorList = [
        'Dark Blue',
        'Yellow',
        'Blue',
        'Green'
    ];

    const renderError = (error) => {
        if (typeof error === 'string') {
            return error;
        } else if (Array.isArray(error)) {
            return error.join(', ');
        } else if (typeof error === 'object' && error !== null) {
            return JSON.stringify(error);
        }
        return '';
    };

    const handleFormSubmit = ( values ) => {
        const descriptionContentState = descriptionEditorState.getCurrentContent();
        const descriptionRawContent = convertToRaw(descriptionContentState);
        const descriptionMarkdown = draftToMarkdown(descriptionRawContent);

        const actionContentState = actionEditorState.getCurrentContent();
        const actionRawContent = convertToRaw(actionContentState);
        const actionStatementMarkdown = draftToMarkdown(actionRawContent);

        let data = {
            title: values.title,
            description: descriptionMarkdown,
            action_statement: actionStatementMarkdown,
            labels: labels,
            score_start: round2Decimals(values.score_start),
            score_end: round2Decimals(values.score_end),
            color: getHexValue(values.color),
            icon: iconString,
        };

        assessmentCommentActions.updateAttribute(attribute._id, data);

        snackbarActions.showSnackbar(
            'Comment attribute was successfully updated.'
        );
        history.push(`/assessment-comment/update/${attribute.assessment_comment_id}`)
    };

    const handleDelete = (id) => {
        assessmentCommentActions.deleteCommentAttribute(id);
        history.push(`/assessment-comment/update/${attribute.assessment_comment_id}`)
    };

    return (
        <Container>
            {
                loading && loading ?
                    <Box style={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress size={72} thickness={3} color="primary"/>
                    </Box> :
                    <Grid container spacing={1}>
                        <Grid item xs={8} style={{ height: '100%', minHeight: '600px', maxHeight: 'auto' }}>
                            <div style={{margin: '0rem 3rem'}}>
                                <Formik
                                    initialValues={{
                                        title: attribute?.title,
                                        description: descriptionEditorState,
                                        action_statement: actionEditorState,
                                        label: '',
                                        score_start: attribute?.score_start,
                                        score_end: attribute?.score_end,
                                        color: getColorName(attribute?.color),
                                        icon: ''
                                    }}
                                    validator={object().shape({
                                        title: string()
                                            .trim()
                                            .matches(
                                                /^[a-zA-Z0-9[\]]+(([',. \-[\]][a-zA-Z0-9 [\]])?[a-zA-Z0-9[\]]*)*$/,
                                                "Enter a valid title"
                                            )
                                            .required('Enter a title'),
                                        score_start: number().nullable().required('Select a starting score'),
                                        score_end: number().nullable().required('Select a end score'),
                                        color: string().nullable().required('Select a color'),
                                    })}
                                    onSubmit={(values) => {
                                        handleFormSubmit(values)
                                    }}
                                >
                                    {(formik) => {
                                        let {errors, setFieldValue, touched, values} = formik;

                                        return (
                                            <Form autoComplete='off'>
                                                <GlobalCss />
                                                <div className={classes.form}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Title
                                                            title='Update'
                                                            text={attribute.title}/>
                                                        <DeleteButtonModal
                                                            title={`Update ${attribute.title}`}
                                                            handleDelete={() => handleDelete(attribute._id)}
                                                        />
                                                    </div>
                                                    {error ? (
                                                        <Grid item>
                                                            <Alert severity='error' onClose={handleErrorClose}>
                                                                {error}
                                                            </Alert>
                                                        </Grid>
                                                    ) : null}
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name="title"
                                                                label="Title"
                                                                placeholder="Title"
                                                                component={TextField}
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoFocus
                                                                style={{marginTop: '1rem'}}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}
                                                              style={{display: 'flex', justifyContent: 'space-between'}}>
                                                            <Field
                                                                name="score_start"
                                                                label="Starting score"
                                                                placeholder="Starting score"
                                                                component={TextField}
                                                                type='number'
                                                                variant="outlined"
                                                                required
                                                                autoFocus
                                                                style={{width: '31%'}}
                                                            />
                                                            <Field
                                                                name="score_end"
                                                                label="End score"
                                                                placeholder="End score"
                                                                component={TextField}
                                                                type='number'
                                                                variant="outlined"
                                                                required
                                                                autoFocus
                                                                style={{width: '31%'}}
                                                            />
                                                            <FormControl variant="outlined" style={{display: 'flex', width: '34%'}}>
                                                                <InputLabel htmlFor="color-label">
                                                                    Color *
                                                                </InputLabel>
                                                                <Field
                                                                    component={Select}
                                                                    name="color"
                                                                    label="Color *"
                                                                    placeholder="Color *"
                                                                    inputProps={{
                                                                        id: "color",
                                                                    }}
                                                                    required
                                                                    renderValue={(selected) => (
                                                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div style={{ backgroundColor: getHexValue(selected), width: 10, height: 10, marginRight: 10 }} />
                                                                            {selected}
                                                                        </Box>
                                                                    )}
                                                                >
                                                                    {colorList && colorList.map((list) => (
                                                                        <MenuItem key={list} value={list}>
                                                                            <Box style={{ backgroundColor: getHexValue(list), width: 10, height: 10, marginRight: 10 }} />
                                                                            {list}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                                {touched["sport"] && !!errors["sport"] && (
                                                                    <FormHelperText>
                                                                        {renderError(errors["sport"])}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControl fullWidth>
                                                                <FormLabel style={{marginBottom: '5px'}}>Description</FormLabel>
                                                                <FormEditor editorState={descriptionEditorState} onEditorStateChange={setDescriptionEditorState} />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControl fullWidth>
                                                                <FormLabel style={{marginBottom: '5px'}}>Action Statement</FormLabel>
                                                                <FormEditor editorState={actionEditorState} onEditorStateChange={setActionEditorState} />
                                                            </FormControl>
                                                        </Grid>
                                                        <IconAndLabels
                                                            labels={labels}
                                                            setLabels={setLabels}
                                                            setFieldValue={setFieldValue}
                                                            value={values.label}
                                                            iconString={iconString}
                                                            setIconString={setIconString}
                                                        />
                                                    </Grid>
                                                    <div className={clsx(classes.content)}>
                                                        <Link
                                                            to={`/assessment-comment/update/${attribute.assessment_comment_id}`}
                                                            className={classes.btnSecondary}
                                                        >
                                                            Cancel
                                                        </Link>
                                                        <Button
                                                            className={classes.btnPrimary}
                                                            type="submit"
                                                        >
                                                            Update
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <RunDashedLogo />
                        </Grid>
                    </Grid>
            }
        </Container>
    )
};

let mapStateToProps = (state) => {
    return {
        error: state.ui.assessmentComments.error,
        loading: state.ui.assessmentComments.selectedAttributeLoading,
        attribute: state.assessmentComments.selectedCommentAttributeData,
    }
};

export default connect(mapStateToProps)(UpdateCommentAttribute);