import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/ClearOutlined";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Editor from "./Editor";
import { Button } from "../Button";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2, 3),
  },
  title: {
    flex: 1,
    fontSize: theme.fontSize.md,
    fontFamily: theme.fontFamily.heading,
    color: theme.colors.primaryNavy,
    textTransform: 'uppercase'
  },
  closeIcon: {
    color: theme.colors.primaryNavy,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 3, 3, 3),
  },
  editor: {
    border: "1px solid #f1f1f1",
    maxHeight: 400,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 0,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.common.white,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.common.white,
    },
  },
  actionBar: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row-reverse",
  },
  btnPrimary: {
    color: theme.palette.common.white,
    padding: '10px',
    backgroundColor: theme.colors.primaryNavy,
    width: '6rem',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.primaryNavy,
      border: `1px solid ${theme.colors.primaryNavy}`,
    },
    textTransform: 'uppercase',
  },
}));

const EditorDialog = (props) => {
  let classes = useStyles();
  let {
    onClose,
    readOnly,
    loading = false,
    onSave,
    onEditorStateChange,
    editorState,
    title,
    ...rest
  } = props;

  let handleCloseDialog = () => onClose();

  let handleSave = () => onSave();

  return (
    <div>
      <div className={classes.header}>
        <div>
          <Typography variant="h6" className={classes.title}>{title}</Typography>
        </div>
        <IconButton onClick={handleCloseDialog}>
          <ClearIcon className={classes.closeIcon}/>
        </IconButton>
      </div>
      <DialogContent className={classes.content}>
        <Editor
          classes={{ editor: classes.editor }}
          onEditorStateChange={onEditorStateChange}
          editorState={editorState}
          readOnly={loading || readOnly}
          {...rest}
        />
        <div className={classes.actionBar}>
          <Button
            variant="contained"
            className={classes.btnPrimary}
            disableElevation
            onClick={handleSave}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default EditorDialog;
