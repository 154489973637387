import React from "react";
import Graph from "./Graph";
import { Article } from "../../../shared/Article";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import NoData from '../../../../assets/NoData.svg';
import { makeStyles } from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  placeholder: {
    display: "block",
    width: '100%',
    textAlign: 'center',
    margin: '1rem 0rem',
    justifyContent: "center",
    alignItems: " center",
  },
  text: {
    fontFamily: theme.fontFamily.heading,
    fontSize: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0rem 2rem",
    width: "auto",
    height: "20vh",
    color: theme.palette.primary.main,
  },
}));

const GraphPanel = (props) => {
  let classes = useStyles();
  const { loadingPlayerFeed, series, source, show } = props;

  if (loadingPlayerFeed === true || show === false) {
    return null;
  }
  let hasData = false;

  series && series.map((s) => {
    if (s.data.length > 0) {
      hasData = true;
    }
  });

  return (
    <Article title="Sentiment Over Time" >
      {hasData ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Graph series={series} />
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}></Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }} >
              {source && source === 'youtube' || source && source === 'newspaper' ? '' :
                <div>
                  <div className={classes.placeholder}>
                    <img src={NoData} alt='No Data' style={{ width: '11rem' }} />
                    <Typography className={classes.text}>No Data</Typography>
                  </div>
                </div>
              }
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
          </Grid>
        </div>
      )}
    </Article>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { selectedIndex, config, playerId } = ownProps;
  const { source, show } = config[selectedIndex];

  return {
    loadingPlayerFeed:
      state.ui.player.loadingPlayerFeed[source][playerId] || false,
    series: state.player.feed[source][playerId]
      ? state.player.feed[source][playerId]["graphs"]
      : [],
    source,
    show,
  };
};

export default connect(mapStateToProps)(GraphPanel);
