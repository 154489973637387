import { Avatar, DialogTitle, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Tooltip } from "@material-ui/core";
import AutoRenew from "@material-ui/icons/Autorenew";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import fileDownload from "js-file-download";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { ActionContext } from "../../../../contexts";
import CircularProgress from "../../../shared/CircularProgress";
import { statusTypes, accountTypes} from "../../../../store/constants";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    fontSize: theme.fontSize.md,
    fontFamily: theme.fontFamily.heading,
    color: theme.colors.primaryNavy,
    textTransform: 'uppercase'
  },
  closeIcon: {
    color: theme.colors.primaryNavy,
  },
  avatar: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.common.white,
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  enabled: {
    color: theme.palette.success.main,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  pending: {
    color: theme.palette.warning.main,
  },
  generate: {
    color: theme.palette.text.primary,
  }
}));

const ReportDialog = (props) => {
  const classes = useStyles();
  const { playerActions } = useContext(ActionContext);

  const {
    status,
    closeDialog,
    reportSettings,
    exportingPsycologistReport,
    playerId,
    playerName,
    accountType,
    exportingFullReport,
    exportingActionPlanReport,
    exportingExecutiveSummaryReport,
    regeneratingFullReport,
    regeneratingActionPlanReport,
    regeneratingExecutiveSummaryReport,
  } = props;

  const isAdmin =
    accountType === accountTypes.superAdmin ||
    accountType === accountTypes.mmDdiAdmin ||
    accountType === accountTypes.mmPsychologist ||
    accountType === accountTypes.mmChiefPsychologist;

  const isFullReportEnabled = isAdmin ? true : reportSettings?.fullReport;
  const isExecutiveSummaryReportEnabled = isAdmin
    ? true
    : reportSettings?.executiveSummaryReport;

  // Action plan validation
  const isActionPlanReportAllowed = isAdmin
    ? true
    : reportSettings?.actionPlanReport;

  const isActionPlanReportReady =
    status === statusTypes.consentPending || status === statusTypes.completed;
  const isActionPlanReportEnabled =
    isActionPlanReportAllowed && isActionPlanReportReady;

  const handleCloseDialog = () => {
    closeDialog();
  };

  const handleFullReport = () => {
    playerActions.exportFullReport(playerId, playerName, (data, name) => {
      return () => {
        fileDownload(data, name);
      };
    });
  };

  const handleActionPlanReport = () => {
    playerActions.exportActionPlanReport(playerId, playerName, (data, name) => {
      return () => {
        fileDownload(data, name);
      };
    });
  };

  const handleExecutiveSummaryReport = () => {
    playerActions.exportExecutiveSummaryReport(
      playerId,
      playerName,
      (data, name) => {
        return () => {
          fileDownload(data, name);
        };
      }
    );
  };

  const handleRegenerateFullReport = () => {
    playerActions.regenerateFullReport(playerId);
  };

  const handleRegenerateActionPlanReport = () => {
    playerActions.regenerateActionPlanReport(playerId);
  };

  const handleRegenerateExecutiveSummaryReport = () => {
    playerActions.regenerateExecutiveSummaryReport(playerId);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <DialogTitle id="simple-dialog-title" className={classes.title}>
          Download Reports
        </DialogTitle>
        <div style={{ margin: "8px 16px 8px 8px" }}>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon className={classes.closeIcon}/>
          </IconButton>
        </div>
      </div>
      <List>
        <ListItem
          disabled={
            !isFullReportEnabled ||
            regeneratingFullReport ||
            exportingFullReport
          }
          onClick={handleFullReport}
          button
        >
          <ListItemAvatar>
            <Tooltip title="Save full report">
              <Avatar className={classes.avatar}>
                {exportingFullReport ? (
                    <CircularProgress color="primary" />
                ) : (
                    <SaveIcon />
                )}
              </Avatar>
            </Tooltip>
          </ListItemAvatar>
          <ListItemText
            className={classes.subtitle}
            primary="Full Report"
            secondary={"Ready"}
            secondaryTypographyProps={{
              className: classes.enabled,
            }}
          />
          <ListItemSecondaryAction>
            <Tooltip title="Regenerate full report">
              <IconButton
                className={classes.generate}
                onClick={handleRegenerateFullReport}
                disabled={
                  !isFullReportEnabled ||
                  regeneratingFullReport ||
                  exportingFullReport
                }
              >
                {regeneratingFullReport ? (
                  <CircularProgress color="primary"/>
                ) : (
                  <AutoRenew />
                )}
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          disabled={
            !isExecutiveSummaryReportEnabled ||
            exportingExecutiveSummaryReport ||
            regeneratingExecutiveSummaryReport
          }
          onClick={handleExecutiveSummaryReport}
          button
        >
          <ListItemAvatar>
            <Tooltip title="Save full report">
              <Avatar className={classes.avatar}>
                {exportingPsycologistReport ? (
                    <CircularProgress color="primary" />
                ) : (
                    <SaveIcon />
                )}
              </Avatar>
            </Tooltip>
          </ListItemAvatar>
          <ListItemText
              className={classes.subtitle}
            primary="Executive Summary Report"
            secondary={"Ready"}
            secondaryTypographyProps={{
              className: classes.enabled,
            }}
          />
          <ListItemSecondaryAction>
            <Tooltip title="Regenerate executive summary report">
              <IconButton
                className={classes.generate}
                onClick={handleRegenerateExecutiveSummaryReport}
                disabled={
                  !isExecutiveSummaryReportEnabled ||
                  regeneratingExecutiveSummaryReport ||
                  exportingExecutiveSummaryReport
                }
              >
                {regeneratingExecutiveSummaryReport ? (
                  <CircularProgress color="primary" />
                ) : (
                  <AutoRenew />
                )}
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          disabled={
            !isActionPlanReportEnabled ||
            exportingActionPlanReport ||
            regeneratingActionPlanReport
          }
          onClick={handleActionPlanReport}
          button
        >
          <ListItemAvatar>
            <Tooltip title="Save full report">
              <Avatar className={classes.avatar}>
                {exportingPsycologistReport ? (
                    <CircularProgress color="primary" />
                ) : (
                    <SaveIcon />
                )}
              </Avatar>
            </Tooltip>
          </ListItemAvatar>
          <ListItemText
            className={classes.subtitle}
            primary="Action Plan Report"
            secondary={isActionPlanReportReady ? "Ready" : "Pending"}
            secondaryTypographyProps={{
              className: isActionPlanReportReady
                ? classes.enabled
                : classes.pending,
            }}
          />
          <ListItemSecondaryAction>
            <Tooltip title="Regenerate actio plan report">
              <IconButton
                className={classes.generate}
                onClick={handleRegenerateActionPlanReport}
                disabled={
                  !isActionPlanReportEnabled ||
                  regeneratingActionPlanReport ||
                  exportingActionPlanReport
                }
              >
                {regeneratingActionPlanReport ? (
                  <CircularProgress color="primary" />
                ) : (
                  <AutoRenew />
                )}
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { playerId } = ownProps;

  return {
    accountType: state.account.accountType,
    reportSettings: state.player.reportSettings[playerId],
    playerName: state.player.basic[playerId]["name"],
    exportingFullReport: state.ui.player.exportingFullReport[playerId] || false,
    exportingActionPlanReport:
      state.ui.player.exportingActionPlanReport[playerId] || false,
    exportingExecutiveSummaryReport:
      state.ui.player.exportingExecutiveSummaryReport[playerId] || false,
    regeneratingFullReport:
      state.ui.player.regeneratingFullReport[playerId] || false,
    regeneratingActionPlanReport:
      state.ui.player.regeneratingActionPlanReport[playerId] || false,
    regeneratingExecutiveSummaryReport:
      state.ui.player.regeneratingExecutiveSummaryReport[playerId] || false,
  };
};

export default connect(mapStateToProps)(ReportDialog);
