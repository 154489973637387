import {Grid, makeStyles, Typography} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {Form, Formik} from "formik";
import React, {useContext, useState} from "react";
import {connect, useDispatch} from "react-redux";
import { object, string } from "yup";
import { ActionContext } from "../../../contexts";
import { errorProps } from "../../../utils";
import Button from "../../shared/Button";
import TextField from "../../shared/TextField";
import BasicInfoArticle from "./BasicInfoArticle";
import {Container, Title} from "../../shared";
import {Article} from "../../shared/Article";
import {showErrorMessage} from "../../shared/Snackbar";
import {GlobalCss} from "../../../config/GlobalCss";
import runDashedLogo from "../../../assets/RunDashedIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

let changePasswordSchema = object().shape({
  currentPassword: string().min(4).max(200).required("Enter current password"),
  newPassword: string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
      "Password doesn't fulfill the requirement"
    )
    .required("Enter new password"),
});

let useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  heading: {
    padding: theme.spacing(2, 2, 1, 2),
    fontFamily: theme.fontFamily.heading,
    color: theme.colors.primaryNavy,
    textTransform: 'uppercase',
  },
  caption: {
    fontFamily: theme.fontFamily.robotoCondensed,
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },
  inputInput: {
    marginBottom: theme.spacing(2),
    fontFamily: theme.fontFamily.robotoCondensed,
    color: theme.colors.primaryNavy,
    textTransform: 'uppercase',
    maxWidth: 600,
  },
  submitBtn: {
    color: theme.palette.common.white,
    padding: '10px',
    backgroundColor: theme.colors.primaryNavy,
    width: '13rem',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.primaryNavy,
      border: `1px solid ${theme.colors.primaryNavy}`,
    },
    textTransform: 'uppercase',
  }
}));

let Settings = (props) => {
  let classes = useStyles();
  let { accountState, accountUiState } = props;
  let { loading: isSubmitting, error } = accountUiState;
  let { full_name, email, team, sport } = accountState;
  const dispatch = useDispatch();

  let [isCurrentPwVisible, setIsCurrentPwVisible] = useState(false);
  let [isNewPwVisible, setIsNewPwVisible] = useState(false);
  let [isConfirmPwVisible, setIsConfirmPwVisible] = useState(false);

  let inputCurrentPasswordType = isCurrentPwVisible ? "text" : "password";
  let inputNewPasswordType = isNewPwVisible ? "text" : "password";
  let inputConfirmPasswordType = isConfirmPwVisible ? "text" : "password";

  let handleCurrentPasswordVisibility = () => {
    setIsCurrentPwVisible(!isCurrentPwVisible);
  };

  let handleNewPasswordVisibility = () => {
    setIsNewPwVisible(!isNewPwVisible);
  };

  let handleConfirmPasswordVisibility = () => {
    setIsConfirmPwVisible(!isConfirmPwVisible);
  };

  let actions = useContext(ActionContext);

  let handleFormSubmit = (values, formBag) => {
    if (values.newPassword === values.confirmPassword) {
      let data = {
        email: email,
        current_password: values.currentPassword,
        new_password: values.newPassword,
      };
      let { accountActions, snackbarActions } = actions;

      let successCb = () => {
        snackbarActions.showSnackbar("Password changed successfully", {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
            autoHideDuration: 3000,
          },
        });
        formBag.resetForm({});
      };

      accountActions.changePassword(data, successCb);
    } else {
      dispatch(showErrorMessage('Confirmed password does not match new password'));
    }
  };

  return (
      <Container>
        <div>
          <BasicInfoArticle user={{full_name, email, team, sport}}/>
          <Article>
            <div className={classes.heading}>
              <Title title='Change Password' />
            </div>
            <div style={{ padding: 16, paddingBottom: 32 }}>
              <div className={classes.caption}>
                <Typography variant="caption">
                  A new password must contain at least 6 characters, One uppercase, One lowercase and One number
                </Typography>
              </div>

              {error ? (
                  <Alert severity="error" style={{marginBottom: 16}}>
                    {error}
                  </Alert>
              ) : null}
              <Formik
                  initialValues={{currentPassword: "", newPassword: "", confirmPassword: ""}}
                  validationSchema={changePasswordSchema}
                  onSubmit={handleFormSubmit}
              >
                {(formik) => {
                  let {
                    handleBlur,
                    handleChange,
                    errors,
                    touched,
                    handleSubmit,
                    values,
                  } = formik;

                  return (
                      <Form
                          style={{display: "flex", flexDirection: 'column'}}
                          noValidate
                          onSubmit={handleSubmit}
                      >
                        <GlobalCss />
                        <Grid container spacing={3}>
                          <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Grid item xs={8}>
                              <TextField
                                  name="currentPassword"
                                  type={inputCurrentPasswordType}
                                  label="Current Password"
                                  placeholder="Current Password"
                                  size="small"
                                  value={values.currentPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fullWidth
                                  required
                                  InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton onClick={handleCurrentPasswordVisibility}>
                                            {isCurrentPwVisible ? (
                                                <Visibility/>
                                            ) : (
                                                <VisibilityOff/>
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                    ),
                                  }}
                                  className={classes.inputInput}
                                  {...errorProps(errors, touched, "currentPassword")}
                              />
                              <TextField
                                  name="newPassword"
                                  type={inputNewPasswordType}
                                  label="New Password"
                                  placeholder="New Password"
                                  size="small"
                                  value={values.newPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fullWidth
                                  required
                                  InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton onClick={handleNewPasswordVisibility}>
                                            {isNewPwVisible ? (
                                                <Visibility/>
                                            ) : (
                                                <VisibilityOff/>
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                    ),
                                  }}
                                  className={classes.inputInput}
                                  {...errorProps(errors, touched, "newPassword")}
                              />
                              <TextField
                                  name="confirmPassword"
                                  type={inputConfirmPasswordType}
                                  label="Confirm Password"
                                  placeholder="Confirm Password"
                                  size="small"
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fullWidth
                                  required
                                  InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton onClick={handleConfirmPasswordVisibility}>
                                            {isConfirmPwVisible ? (
                                                <Visibility/>
                                            ) : (
                                                <VisibilityOff/>
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                    ),
                                  }}
                                  className={classes.inputInput}
                                  {...errorProps(errors, touched, "newPassword")}
                              />
                              <div style={{width: '100%', maxWidth: 600, display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    type="submit"
                                    className={classes.submitBtn}
                                    loading={isSubmitting}
                                    disableElevation
                                >
                                  Change
                                </Button>
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div style={{display: 'flex', height: '100%', maxHeight: '550px', alignItems: 'center', justifyContent: 'center'}}>
                                <img
                                    src={runDashedLogo}
                                    alt="logo"
                                    style={{width: '70%'}}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                  );
                }}
              </Formik>
            </div>
          </Article>
        </div>
      </Container>
  );
};

let mapStateToProps = (state) => {
  return {
    isDrawerOpen: state.ui.app.isDrawerOpen,
    accountState: state.account.accountData,
    accountUiState: state.ui.account.changePassword,
  };
};

export default connect(mapStateToProps)(Settings);
