import {ChildTab, MainTab} from "../../../shared/Tabs";
import {useContext, useEffect, useState} from "react";
import {TabPanel} from "../../../shared";
import Disc from "../../../../assets/disc.svg";
import {connect} from "react-redux";
import {ActionContext} from "../../../../contexts";
import {makeStyles, Typography} from "@material-ui/core";

const Communications = (props) => {
    const [selectedPage, setSelectedPage] = useState(0);
    const { basicInfo, communicationData, playerId } = props;
    const {playerActions} = useContext(ActionContext);

    useEffect(() => {
        playerActions.getCommunicationDataByProfileId(playerId);
    }, [playerActions, playerId]);

    let [dAxis, setDAxis] = useState(0);
    let [iAxis, setIAxis] = useState(0);
    let [sAxis, setSAxis] = useState(0);
    let [cAxis, setCAxis] = useState(0);
    let [axisArray, setAxisArray] = useState([]);
    let [pointY, setPointY] = useState(0);
    let [pointX, setPointX] = useState(0);

    useEffect(() => {
        setDAxis(communicationData?.disc_dimensions?.d_axis);
        setIAxis(communicationData?.disc_dimensions?.i_axis);
        setSAxis(communicationData?.disc_dimensions?.s_axis);
        setCAxis(communicationData?.disc_dimensions?.c_axis);
    }, [communicationData]);

    useEffect(() => {
        setAxisArray([dAxis, iAxis, sAxis, cAxis]);
    }, [dAxis, iAxis, dAxis, cAxis]);

    useEffect(() => {
        let sortedArray = axisArray.sort((a, b) => b - a);
        let sortValue = sortedArray[0];
        let spaceValue = 110 - sortValue;
        let angleDeg;

        const centerX = 500;
        const centerY = 500;
        const r = 330;

        switch (sortValue) {
            case dAxis:
                angleDeg = 315;
                break;
            case iAxis:
                angleDeg = 45;
                break;
            case sAxis:
                angleDeg = 135;
                break;
            case cAxis:
                angleDeg = 225;
                break;
            default:
                angleDeg = 0;
        }

        const radians = angleDeg * (Math.PI / 180);

        switch (sortValue) {
            case dAxis:
            case iAxis:
                setPointY(centerY - (Math.cos(radians) * r) + spaceValue);
                break;
            case sAxis:
            case cAxis:
                setPointY(centerY - (Math.cos(radians) * r) - spaceValue);
                break
            default:
                setPointY(0);
        }

        switch (sortValue) {
            case dAxis:
            case cAxis:
                setPointX(centerX + (Math.sin(radians) * r) + spaceValue);
                break;
            case sAxis:
            case iAxis:
                setPointX(centerX + (Math.sin(radians) * r) - spaceValue);
                break;
            default:
                setPointX(0);
        }
    }, [axisArray, cAxis, dAxis, iAxis, sAxis]);

    const useStyles = (makeStyles(theme => ({
        overviewContainer: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            margin: "0rem 2rem",
        },
        mainBox: {
            position: 'relative',
            backgroundImage: `url(${Disc})`,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            width: 1000,
            height: 1000,
            transition: `transform 0.5s ease-in`,
            [theme.breakpoints.down(1540)]: {
                transform: 'scale(0.9)',
                transformOrigin: 'center',
            },
            [theme.breakpoints.down(1200)]: {
                transform: 'scale(0.7)',
                transformOrigin: 'center',
            },
            [theme.breakpoints.down(1000)]: {
                transform: 'scale(0.5)',
                transformOrigin: 'center'
            },
        },
        dot: {
            position: 'absolute',
            backgroundColor: theme.palette.text.primary,
            top: pointY,
            left: pointX,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
        },
    })));
    const classes = useStyles();

    const communicationLabels = [
        "Your Athlete",
        "About Dominant Styles",
        "About Influential Styles",
        "About Steady Styles",
        "About Compliant Styles",
    ];

    const handlePageSelect = (event, index) => {
        setSelectedPage(index);
    };

    return (
        <div className={classes.overviewContainer}>
            <MainTab
                value={selectedPage}
                onChange={handlePageSelect }
            >
                {
                    communicationLabels.map((list, index) => (
                        <ChildTab
                            key={index}
                            label={list}
                        />
                    ))
                }
            </MainTab>
            <TabPanel
                value={selectedPage}
                index={0}
            >
                {
                    Object.keys(communicationData).length !== 0 ? (
                        <div className={classes.mainBox}>
                            <div className={classes.dot} />
                        </div>
                    ) : (
                        <Typography >
                            No DISC profile for {basicInfo.name}
                        </Typography>
                    )
                }
            </TabPanel>
        </div>
    )
};

let mapStateToProps = (state) => {
    return {
        communicationData: state.player.selectedCommunicationData,
    };
};

export default connect(mapStateToProps)(Communications);