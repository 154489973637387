import {makeStyles} from "@material-ui/core";

let formStyles = makeStyles((theme) => ({
    content: {
        display: "flex",
        justifyContent: "end",
        margin: '2rem 0rem 0rem 0rem'
    },
    btnPrimary: {
        color: theme.palette.common.white,
        fontFamily: theme.fontFamily.robotoCondensed,
        padding: '10px',
        backgroundColor: theme.colors.primaryNavy,
        width: '13rem',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        fontFamily: theme.fontFamily.robotoCondensed,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
        textDecoration: 'none',
        textAlign: 'center',
        fontWeight: 600,
    },
    labelsInput: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    labelItem: {
        display: 'flex',
        flexGrow: 1,
        maxWidth: '50%',
        height: 30,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
        borderRadius: 12,
        backgroundColor: theme.colors.veryLightGrey,
        fontSize: '0.8rem',
        padding: theme.spacing(1),
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    labelItemSpacing: {
        '& + &': {
            marginLeft: theme.spacing(1),
        }
    },
    iconBtn: {
        color: theme.palette.common.white,
        fontFamily: theme.fontFamily.robotoCondensed,
        padding: '10px',
        width: '15rem',
        textAlign: 'center',
        height: 55,
        backgroundColor: theme.colors.primaryNavy,
        margin: 'auto 0px',
        borderRadius: '7px',
        textTransform: 'uppercase',
    },
    error: {
        color: theme.palette.error.main,
        marginTop: 15,
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    formCard: {
        padding: '0.8rem',
        border: `1px solid ${theme.colors.lightGrey}`,
        borderRadius: '8px',
        borderLeft: `5px solid ${theme.palette.primary.main}`,
    },
    cardTitle: {
        fontSize: '1.2rem',
        fontFamily: theme.fontFamily.heading,
        marginBottom: '10px',
    },
    noIcon: {
        border: 'dashed 1px #D8D8D8',
        width: 120,
        height: 120,
        backgroundColor: '#f3f3f3',
        borderRadius: 4,
    },
    flexStart: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    iconName: {
        fontSize: '1rem',
        marginTop: 15,
        marginLeft: 5,
    },
    iconContainer: {
        marginTop: '1rem',
        border: `${theme.colors.lightGrey} 1px solid`,
        borderRadius: 8,
        padding: '1rem',
    }
}));

export default formStyles;