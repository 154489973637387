import {makeStyles} from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
    fullName: {
        color: theme.colors.primaryNavy,
        fontFamily: theme.fontFamily.heading,
    },
    team: {
        color: theme.colors.darkBlue60Percent,
        fontSize: '12px',
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    status: {
        fontSize: '11px',
        padding: '0.1rem 0.7rem',
        borderRadius: '0.9rem',
        fontWeight: 500,
    },
    avatar: {
        backgroundColor: theme.colors.primaryNavy,
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    secondaryAction: {
        display: "flex",
        flexDirection: "column",
    },
    badgeContent: {
        width: 18,
        height: 18,
        backgroundColor: theme.palette.background.paper,
        border: `2px solid #fff`,
    },
    card: {
        flexGrow: 0,
        padding: '14px',
        borderRadius: '9px',
        boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.06)',
        backgroundColor: theme.colors.white,
        borderLeft: `5px solid ${theme.palette.primary.main}`,
        '&:hover': {
            cursor: 'pointer',
        },
        color: theme.colors.primaryNavy,
        minHeight: 85,
    },
    search: {
        justifyContent: 'space-between',
        display: 'flex',
        position: 'relative',
        marginBottom: '14px',
        marginRight: '0px',
        marginTop: '14px',
        backgroundColor: theme.palette.common.white,
        marginLeft: 0,
        width: '100%',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        padding: '1rem 0px',
    },
    searchIcon: {
        padding: '0px 5px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.main,
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: '8px 15px',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        paddingLeft: '10px',
        maxWidth: 330 ?? '100%',
        borderRadius: 5,
        color: theme.colors.darkBlue60Percent,
        backgroundColor: theme.colors.veryLightGrey,
        fontFamily: theme.fontFamily.robotoCondensed,
        marginRight: '1rem',
        fontWeight: 500,
        border: 'none',
    },
    btnPrimary: {
        color: theme.palette.common.white,
        padding: '10px',
        backgroundColor: theme.colors.primaryNavy,
        width: '13rem',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
    },
    ArrowAlignSort: {
        display: 'inline-grid',
        position: 'relative',
        top: '-5px'
    },
    arrowAlignFlex: {
        height: '0.5em'
    },
    title: {
        fontSize: theme.fontSize.md,
        fontFamily: theme.fontFamily.heading,
        color: theme.colors.primaryNavy,
        textTransform: 'uppercase'
    },
    subtitle: {
        fontWeight: 600,
        fontSize: '1.2rem',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.secondary.main,
    },
    radioBtnContainer: {
        margin: '0.5rem 0rem',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    radioBtn: {
        color: theme.colors.primaryNavy,
    },
    dropdown: {
        padding: '8px 15px',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: "98%",
        borderRadius: 5,
        color: theme.colors.darkBlue60Percent,
        backgroundColor: theme.colors.veryLightGrey,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontWeight: 500,
        border: 'none',
        height: 50,
    },
    dropdownOptions: {
        background: theme.colors.white,
        border: `1px solid ${theme.colors.white}`,
        padding: '20px',
        fontSize: 15,
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
    },
    content: {
        display: "flex",
        justifyContent: "end",
        marginBottom: '1rem'
    },
}));

export default useStyles;