import React, {useState, useContext, useEffect} from "react";
import ListLoading from "./ListLoading";
import { connect } from "react-redux";
import {Card, fade, Grid, makeStyles, Typography} from "@material-ui/core"
import { ActionContext } from "../../../../contexts";
import {HeaderButton, SearchBox, Title} from "../../../shared";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import {accountTypes} from "../../../../store/constants";

let useStyles = makeStyles((theme) => ({
  fullName: {
    color: theme.colors.primaryNavy,
    fontFamily: theme.fontFamily.heading,
    cursor: 'pointer',
  },
  card: {
    flexGrow: 0,
    padding: '14px',
    borderRadius: '9px',
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.06)',
    backgroundColor: theme.palette.common.white,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    color: theme.colors.primaryNavy,
  },
  search: {
    justifyContent: 'space-between',
    display: 'flex',
    position: 'relative',
    marginBottom: '14px',
    marginRight: '0px',
    marginTop: '14px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    marginLeft: 0,
    width: '100%',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: '1rem 0px',
  },
  headerBtn: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem',
    fontSize: '13px',
    fontFamily: theme.fontFamily.robotoCondensed,
    color: theme.palette.text.primary,
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '8px 15px',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    paddingLeft: '10px',
    maxWidth: 330 ?? '100%',
    borderRadius: 5,
    color: theme.colors.darkBlue60Percent,
    backgroundColor: theme.colors.veryLightGrey,
    fontFamily: theme.fontFamily.robotoCondensed,
    fontWeight: 500,
    border: 'none',
  },
    iconColor: {
        color: theme.palette.text.primary,
        cursor: 'pointer',
    },
}));

let SportList = (props) => {
  let classes = useStyles();
  let { accountType, loading, setOpen, sportsData } = props;
  let actions = useContext(ActionContext);
  let { sportsActions } = actions;
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    sportsActions.getSportsList();
  }, []);

  function handleCardClick(sportId, name) {
    sportsActions.getSportPositionDetails(sportId);
    sportsActions.selectSport(sportId);
    sportsActions.selectSportsPage("unit")
    sportsActions.selectSportsType(name);
  }

  const filteredData = searchText ? sportsData && sportsData.filter(v => v.name.toLowerCase().includes(searchText.toLowerCase())) : sportsData && sportsData;
  const sortedFilteredData = filteredData && filteredData.sort((a, b) => a.name.localeCompare(b.name));

  const handleViewAll = () => {
    sportsActions.getSportsList()
    setSearchText('');
  };

  const handleSearch = (event) => {
    const search = event.target.value;
    setSearchText(search);
  };

  const handleCreate = (page) => {
    sportsActions.selectedFormName(page);
    sportsActions.selectedFormState('create');
    sportsActions.deselectSport();
    sportsActions.deselectSportUnit()
    sportsActions.deselectPosition();
    setOpen(true);
  };

  const handleUpdateSport = (sportId) => {
    sportsActions.selectSportById(sportId);
    sportsActions.selectSport(sportId);
    sportsActions.selectedFormName('sport');
    sportsActions.selectedFormState('update');
    setOpen(true);
  };

  return (
      <>
        {accountType === accountTypes.superAdmin ? (
            <div className={classes.search}>
              <Title title='Sports' />
              <div style={{display: 'flex'}}>
                <HeaderButton
                    title="Create Sport"
                    onclick={() => handleCreate('sport')}
                />
                <HeaderButton
                    onclick={handleViewAll}
                    title='View All'
                />
                <SearchBox
                    placeholder="Search Assessment Comment"
                    value={searchText}
                    onChange={(e) => handleSearch(e)}
                    style={{ marginRight: '-1rem' }}
                />
              </div>
            </div>
        ) : (
            <div className={classes.search}>
              <Title title='Sports'/>
              <div style={{display: 'flex'}}>
                <HeaderButton
                    onclick={handleViewAll}
                    title='View All'
                />
                <SearchBox
                    placeholder="Search Assessment Comment"
                    value={searchText}
                    onChange={(e) => handleSearch(e)}
                    style={{marginRight: '-1rem'}}
                />
              </div>
            </div>
        )}
        <Grid container spacing={1}>
          {
            loading ?
                <ListLoading/>
                :
                sortedFilteredData && sortedFilteredData.length === 0 ?
                    <Typography
                        style={{display: 'flex', justifyContent: 'center', marginTop: '10px', color: '#e61e21'}}>
                      No sports found
                    </Typography>
                    :
                    sortedFilteredData && sortedFilteredData.map(list => (
                        accountType === accountTypes.superAdmin ? (
                                <Grid item xs={12} sm={4}>
                                  <Card className={classes.card}>
                                    <div style={{display: 'flex'}}>
                                      <div style={{width: '100%', marginLeft: '1rem'}}>
                                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <div onClick={() => handleCardClick(list._id, list.name)}>
                                                    <Typography className={classes.fullName}>{list.name}</Typography>
                                                </div>
                                                <div onClick={() => handleUpdateSport(list._id)}>
                                                    <CreateOutlinedIcon className={classes.iconColor}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        ) :
                            <Grid item xs={12} sm={4}>
                                <Card className={classes.card}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: '100%', marginLeft: '1rem'}} onClick={() => handleCardClick(list._id, list.name)}>
                                            <Typography className={classes.fullName}>{list.name}</Typography>
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                    ))
          }
        </Grid>
      </>
  );
};

let mapStateToProps = (state) => {
  return {
    sportsData: state.sports.sportsListData,
    loading: state.ui.sports.sportsListLoading,
    accountType: state.account.accountType,
  };
};

export default connect(mapStateToProps)(SportList);
