import {CircularProgress, Container, HeaderButton, SearchBox, Title} from "../shared";
import {connect} from "react-redux";
import tableStyles from "../shared/stylessheets/tableStyles";
import React, {useContext, useEffect, useState} from "react";
import {ActionContext} from "../../contexts";
import {Box, Grid, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import {useHistory} from "react-router-dom";

const Communications = (props) => {
    const classes = tableStyles();
    const { assessmentCommentActions } = useContext(ActionContext);
    const { communications, loading } = props;
    const [value, setValue ] = useState('');
    const history = useHistory()

    useEffect(() => {
        assessmentCommentActions.getAllCommunications();
    }, []);

    function handleViewAllChange() {
        assessmentCommentActions.getAllCommunications();
    }

    const handleSearch = (event) => {
        const search = event.target.value;
        setValue(search);
    };

    const filterData = value.length > 0 ? communications?.filter(item => (
        item.scale.toLowerCase().includes(value.toLowerCase())
    )) : communications;

    const handleEditClick = (id) => {
        assessmentCommentActions.getCommunicationById(id);
        history.push(`/update-assessment-communications/${id}`);
    };

    return (
        <Container>
            <div className={classes.header}>
                <Title title='Communications' />
                <div style={{display: 'flex'}}>
                    <HeaderButton
                        onclick={handleViewAllChange}
                        title='View All'
                    />
                    <SearchBox
                        placeholder="Search Assessment Comment"
                        value={value}
                        onChange={(e) => handleSearch(e)}
                        style={{ marginRight: '-1rem' }}
                    />
                </div>
            </div>
            <TableContainer>
                <Grid container style={{ padding: '1rem 0px' }}>
                    {
                        loading && loading ?
                            <Box style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                <CircularProgress size={72} thickness={3} color='primary' />
                            </Box> : (
                                filterData && filterData.length === 0 ? (
                                    <Typography className={classes.noDataFound}>
                                        No communication content found
                                    </Typography>
                                ) : (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TableHead className={classes.tableHead}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        className={classes.fullName}
                                                        style={{ textAlign: 'start' }}
                                                    >
                                                       title
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.fullName}>
                                                        do tip 1 headline
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.fullName}>
                                                        don't tip 1 headline
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.fullName}>
                                                        edit
                                                    </Grid>
                                                </Grid>
                                            </TableHead>
                                            {
                                                filterData && filterData?.map(result => (
                                                    <TableRow className={classes.rowAlignment} key={result._id}>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            className={classes.fullName}
                                                            style={{ textAlign: 'start' }}
                                                        >
                                                            {result.scale}
                                                        </Grid>
                                                        <Grid item xs={4} className={classes.dataTableAlignment}>
                                                            {result.do_tip_1_headline}
                                                        </Grid>
                                                        <Grid item xs={4} className={classes.dataTableAlignment}>
                                                            {result.dont_tip_1_headline}
                                                        </Grid>
                                                        <Grid item xs={2} className={classes.dataTableAlignment}>
                                                            <div onClick={() => handleEditClick(result._id)}>
                                                                <CreateOutlinedIcon className={classes.iconColor}/>
                                                            </div>
                                                        </Grid>
                                                    </TableRow>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            )
                    }
                </Grid>
            </TableContainer>
        </Container>
    )
};

let mapStateToProps = (state) => {
    return {
        loading: state.ui.assessmentComments.loadingCommunications,
        communications: state.assessmentComments.allCommunications,
    };
};

export default connect(mapStateToProps)(Communications);