import {makeStyles} from "@material-ui/core";

const tableStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        paddingBottom: '1rem',
    },
    headerBtn: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem',
        fontSize: '13px',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        fontWeight: 700,
        cursor: 'pointer',
        textTransform: 'uppercase',
        textDecoration: 'none',
    },
    noDataFound: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1rem',
        color: theme.palette.error.main,
        fontFamily: theme.fontFamily.heading,
        fontSize: '1.1rem',
    },
    rowAlignment: {
        border: 'solid 1px #f2f2f2',
        borderRadius: 8,
        backgroundColor: theme.palette.common.white,
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8rem 0.5rem',
        width: '100%',
    },
    tableHead: {
        borderBottom: 'solid 1px #f2f2f2',
        backgroundColor: theme.palette.common.white,
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8rem 0.5rem',
        width: '100%',
    },
    fullName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '1.2em',
        whiteSpace: 'nowrap',
        fontWeight: '700',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
        textAlign: 'center',
    },
    dataTableAlignment: {
        fontWeight: '300',
        display: 'flex',
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: 14,
        justifyContent: 'center',
        textTransform: 'capitalize',
    },
    iconColor: {
        color: theme.palette.text.primary,
    },
}));

export default tableStyles;