import React, { useContext, useState, useEffect } from 'react'
import { connect, useSelector } from "react-redux";
import {
    Button,
    Tooltip,
    makeStyles,
    Typography,
    Box,
} from "@material-ui/core"
import PlayerAttributes from './PlayerAttributes';
import { ActionContext } from "../../../contexts";
import { CircularProgress, TabPanel } from "modules/shared";
import InfoIcon from "@material-ui/icons/Info";
import CommunicationStyles from './Communication/comminicationStyles';
import {ChildTab, MainTab} from "../../shared/Tabs";
import {accountTypes} from "../../../store/constants";

let useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.veryLightGrey,
        zIndex: theme.zIndex.appBar,
        justifyContent: 'center',
        alignContent: 'center',
        width: '100%',
        padding: '3rem 2rem',
    },
    header: {
        display: "flex",
        alignItems: 'center',
        fontFamily: theme.fontFamily.heading,
        color: theme.palette.text.primary,
        marginBottom: '1rem',
    },
    headerText: {
        fontSize: 22,
        marginRight: 5,
    },
    filterContainer: {
        display: 'flex',
        marginBottom: 20,
        padding: '0.5rem 1rem',
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
        borderLeft: `5px solid ${theme.palette.primary.main}`,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    filterTabs: {
        display: "flex",
    },
    inputInput: {
        padding: '8px 15px',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: 185,
        borderRadius: 5,
        color: theme.palette.text.disabled,
        backgroundColor: theme.colors.veryLightGrey,
        fontFamily: theme.fontFamily.robotoCondensed,
        marginRight: '1rem',
        fontWeight: 500,
        border: 'none',
        height: 35,
    },
    sportOptions: {
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.common.white}`,
        padding: 20,
        fontSize: 15,
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    btnSecondary: {
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.common.white,
        border: `1px solid ${theme.palette.text.primary}`,
        padding: '5px 10px',
        marginRight: 10,
        borderRadius: 8,
        fontSize: 13,
        height: 35,
        fontWeight: 600,
        fontFamily: theme.fontFamily.robotoCondensed,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.text.primary,
        }
    },
    btnPrimary: {
        color: theme.palette.common.white,
        padding: '5px 0px',
        backgroundColor: theme.palette.primary.main,
        width: '8rem',
        borderRadius: 8,
        fontSize: 13,
        height: 35,
        fontWeight: 600,
        fontFamily: theme.fontFamily.robotoCondensed,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    container: {
        flexGrow: 1,
        display: "flex",
        margin: "0px",
        backgroundColor: theme.palette.common.white,
        boxShadow: "3px 3px 10px 3px #cdcdcd",
        borderRadius: 8,
    },
    sideBarTitle: {
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: '1rem',
        fontWeight: 700,
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
    },
    selectedSideBarTitle: {
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: '1rem',
        fontWeight: 700,
        textTransform: 'capitalize',
        color: theme.palette.primary.main,
        backgroundColor: theme.colors.veryLightGrey,
    },
    tabPanel: {
        width: '100%',
        borderRadius: "0px 8px 8px 0px",
        padding: '0px 1rem',
        paddingBottom: '1rem',
    },
}));

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function TeamMain(props) {
    let classes = useStyles();
    let { accountType, updateTeamFilter, loadingPlayerFilters, loadingPositions, sportsData, sportPositions, team, unitData } = props;
    let { playerActions, sportsActions } = useContext(ActionContext);
    let accountState = useSelector((state) => state.account.accountData);
    const [selectedPage, setSelectedPage] = useState(0);
    const [positions, setPositions] = useState([]);
    const selectedSport = sportsData.find(sport => sport.name === accountState.sport);
    const coachTeam = accountState.sport !== "" ? accountState.sport : '';
    const [sport, setSport] = useState(coachTeam);
    const [unit, setUnit] = useState(updateTeamFilter.unit);
    const [position, setPosition] = useState(updateTeamFilter.position);
    const [athleteType, setAthleteType] = useState(updateTeamFilter.athlete_type);

    useEffect(() => {
        sportsActions.getSportsList();
        playerActions.getTeamByName(accountState.team)
    }, [])

    useEffect(() => {
        if (loadingPlayerFilters === true) {
            playerActions.isTeamFilteredData(true)
        }
    }, [loadingPlayerFilters]);

    useEffect(() => {
        playerActions.updateTeamFilterValue("sport", coachTeam)
        sportsActions.getSportUnitDetails(selectedSport?._id);
    }, [accountType, selectedSport?._id]);

    useEffect(() => {
        if (selectedSport?.name) {
            const model = {
                athlete_type: '',
                position: '',
                unit: '',
                sport: selectedSport?.name,
            }
            playerActions.getAllFiltersPlayers(model)
            sportsActions.getSportPositionDetails(team.sport_id)
        }
    }, [selectedSport]);

    useEffect(() => {
        if (positions.length < 1) {
            setPositions(sportPositions)
        }
    }, [sportPositions])

    const handleChange = (event, newValue) => {
        setSelectedPage(newValue);
    };

    const recruit = [
        {
            "name": "Rostered Player",
            "id": "Roster"
        },
        {
            "name": "Potential Recruit",
            "id": "Recruit"
        }
    ];

    const handlePlayerChange = (event) => {
        setAthleteType(event.target.value);
        playerActions.updateTeamFilterValue("athlete_type", event.target.value)
    };

    const handleSportChange = (event) => {
        setSport(event.target.value);
        playerActions.updateTeamFilterValue("sport", event.target.value)
        sportsActions.getSportUnitDetails(event.target.value);
    };

    const handleUnitChange = (event) => {
        setUnit(event.target.value);
        playerActions.updateTeamFilterValue("unit", event.target.value)
        setPositions(sportPositions.filter(p => p.unit_name === event.target.value)
            .map(p => {
                return {
                    id: p._id,
                    position: p.position
                }
        }));
    };

    const handlePositionChange = (event) => {
        setPosition(event.target.value);
        playerActions.updateTeamFilterValue("position", event.target.value)
    };

    const handleCancel = () => {
        playerActions.resetTeamFilterValue();
        playerActions.updateTeamFilterValue("sport", coachTeam);
        sportsActions.resetSportsApi();

        let model = {
            sport: coachTeam,
            unit: '',
            position: '',
            athlete_type: '',
        };

        setPosition('');
        setSport(coachTeam);
        setUnit('');
        setAthleteType('');
        setPositions(sportPositions);
        playerActions.getAllFiltersPlayers(model);
    };

    const handleSave = () => {
        playerActions.getAllFiltersPlayers(updateTeamFilter);
        setPositions(sportPositions);
    };

    const info = "Team Management allows you to see the average of the Player Attributes and Behavior Risk Factors for your Rostered Players. This dashboard allows you to see at-a-glance who your leaders are across Player Attributes and Risk Factors as well as the individuals that may need more customized development plans. Toggle between each of the Player Attributes and Risk Factors to get a better sense of your team holistically.";

    return (
        <div className={classes.root}>
            {
                loadingPositions && loadingPositions ? (
                    <Box>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <div className={classes.header}>
                            <Typography className={classes.headerText}>
                                {sport ? sport : coachTeam} Team Overview
                            </Typography>
                            <Tooltip arrow title={info}>
                                <InfoIcon style={{cursor: 'pointer'}}/>
                            </Tooltip>
                        </div>
                        <div className={classes.filterContainer}>
                            <div className={classes.filterTabs}>
                                {
                                    (accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist || accountType === accountTypes.proSecurity)
                                        ?
                                        <select
                                            value={athleteType}
                                            onChange={handlePlayerChange}
                                            name="athleteType"
                                            className={classes.inputInput}
                                        >
                                            <option value="" disabled selected hidden>Athlete Type</option>
                                            {
                                                recruit && recruit.map(list =>
                                                    <option
                                                        className={classes.sportOptions}
                                                        key={list.id}
                                                        value={list.id}
                                                    >
                                                        {list.name}
                                                    </option>
                                                )
                                            }
                                        </select>
                                        :
                                        ""
                                }
                                {
                                    (accountType === accountTypes.colPsychologist) ?
                                        <select
                                            value={sport}
                                            onChange={handleSportChange}
                                            name="sport"
                                            className={classes.inputInput}
                                        >
                                            <option value="" disabled selected hidden>Sport</option>
                                            {
                                                sportsData && sportsData.map(list =>
                                                    <option
                                                        className={classes.sportOptions}
                                                        key={list._id}
                                                        value={list.name}
                                                    >
                                                        {list.name}
                                                    </option>
                                                )
                                            }
                                        </select>
                                        :
                                        ""
                                }
                                {
                                    (accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist || accountType === accountTypes.proSecurity)
                                        ?
                                        <select
                                            value={unit}
                                            onChange={handleUnitChange}
                                            name="unit"
                                            className={classes.inputInput}
                                        >
                                            <option value="" disabled selected hidden>Unit</option>
                                            {
                                                unitData && unitData.map(list =>
                                                    <option
                                                        className={classes.sportOptions}
                                                        key={list.id}
                                                        value={list.unit_name}
                                                    >
                                                        {list.unit_name}
                                                    </option>
                                                )
                                            }
                                        </select>
                                        :
                                        ""
                                }
                                {
                                    (accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist || accountType === accountTypes.proSecurity)
                                        ?
                                        <select
                                            value={position}
                                            onChange={handlePositionChange}
                                            name="position"
                                            className={classes.inputInput}
                                        >
                                            <option value="" disabled selected hidden>Position</option>
                                            {
                                                positions && positions.map(list =>
                                                    <option
                                                        className={classes.sportOptions}
                                                        key={list._id}
                                                        value={list.position}
                                                    >
                                                        {list.position}
                                                    </option>
                                                )
                                            }
                                        </select>
                                        :
                                        ""
                                }
                            </div>
                            {
                                accountType === accountTypes.proScouts ? "" :
                                    <div>
                                        <Button className={classes.btnSecondary}
                                                disabled={Object.keys(updateTeamFilter).length === 0}
                                                onClick={handleCancel}>
                                            Clear
                                        </Button>
                                        <Button className={classes.btnPrimary}
                                                disabled={Object.keys(updateTeamFilter).length === 0}
                                                onClick={handleSave}>
                                            Filter
                                        </Button>
                                    </div>
                            }
                        </div>
                        <div className={classes.container}>
                            {
                                loadingPlayerFilters ?
                                    <CircularProgress size={72} thickness={3} color="primary"/>
                                    :
                                    <>
                                        <MainTab
                                            orientation="vertical"
                                            variant="scrollable"
                                            value={selectedPage}
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                            style={{minWidth: 200}}
                                        >
                                            <ChildTab
                                                label="Player Attributes"
                                                {...a11yProps(0)}
                                                className={selectedPage === 0 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                                            />
                                            <ChildTab
                                                label="Communication"
                                                {...a11yProps(1)}
                                                className={selectedPage === 1 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                                            />
                                        </MainTab>
                                        <>
                                            <TabPanel
                                                value={selectedPage}
                                                index={0}
                                                className={classes.tabPanel}
                                            >
                                                <PlayerAttributes/>
                                            </TabPanel>
                                            <TabPanel
                                                value={selectedPage}
                                                index={1}
                                                className={classes.tabPanel}
                                            >
                                                <CommunicationStyles/>
                                            </TabPanel>
                                        </>
                                    </>
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}

let mapStateToProps = (state) => {
    return {
        updateTeamFilter: state.player.updateTeamFilter,
        loadingPlayerFilters: state.ui.player.loadingPlayerFilters,
        sportsData: state.sports.sportsListData,
        loadingPositions: state.ui.sports.loadingSportsPositions,
        sportPositions: state.sports.sportPositions,
        isTeamFiltered: state.player.isTeamFiltered,
        accountType: state.account.accountType,
        team: state.player.selectedTeamByName,
        unitData: state.sports.unitsBySportId,
    }
}

export default connect(mapStateToProps)(TeamMain)