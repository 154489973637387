import React, {useState, useContext} from 'react'
import { Card, Avatar, Badge, Tooltip, Grid, InputBase, Slide } from "@material-ui/core"
import { connect } from "react-redux";
import ErrorIcon from "@material-ui/icons/ErrorRounded";
import ListLoading from "./ListLoading"
import { ActionContext } from "../../../contexts";
import PlayerDetails from "../components/PlayerDetails";
import _ from 'lodash'
import SortingPopover from './SortingPopOver';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { accountTypes } from "../../../store/constants";
import {HeaderButton, Title} from "../../shared";
import useStyles from "./styles";
import useStatusSelector from "../../../utils/useStatusSelector";

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="left"
            ref={ref}
            timeout={{ enter: 1000, exit: 1000 }}
            {...props}
        />
    );
});

function List(props) {
    let classes = useStyles();
    const { pageChange, loading, filterCandidates, isFilteredApply, isFiltered, accountType, sortingOrderBy, sortingOrder } = props;
    const { getBackgroundColor, getColor } = useStatusSelector();
    let actions = useContext(ActionContext);

    let { playerActions, dialogActions, sportsActions } = actions;

    const [searchText, setSearchText] = useState(undefined);

    function handleSearchText(v) {
        setSearchText(v);
    }
    
    const handleCardClick = (playerId) => {
        playerActions.getPlayer(playerId);
        playerActions.selectPlayer(playerId);

        dialogActions.openDialog(<PlayerDetails playerId={playerId} />, {
            fullScreen: true,
            TransitionComponent: Transition,
        });
    };

    const handleResetFilters = () => {
        let model = {
            conference: [],
            sport: [],
            unit: [],
            position: [],
            athleteType: [],
            conferenceType: [],
            team: []
        };

        playerActions.getAllFiltersCandidates(model)
        isFilteredApply(false)
        playerActions.isFilteredData(false)
        playerActions.resetFilterValue()

        if (accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach) {
            sportsActions.resetSportsApi()
        } else {
            sportsActions.resetUnitsApi()
            sportsActions.resetSportsApi()
        }
    };

    const options = [
        { value: '', label: 'SortBy' },
        { value: 'name', label: 'Name' },
        { value: 'createdBy', label: 'CreatedBy' },
        { value: 'status', label: 'Status' }
    ];

    const [selected] = useState(options[0].value);

    const sortData = (selected && selected === 'name') ? _.orderBy(filterCandidates, ['full_name'], ['desc']) : (selected && selected === 'status') ? _.orderBy(filterCandidates, ['status'], ['desc']) : filterCandidates && filterCandidates;
    const filteredDetails = searchText ? sortData && sortData.filter(v => v.full_name.toLowerCase().includes(searchText.toLowerCase())) : sortData && sortData;

    let filteredData = _.isEmpty(sortingOrderBy) ? filteredDetails : _.orderBy(filteredDetails, [(sortingOrderBy && sortingOrderBy[0] === "created_on") ? (o) => new Date(o.created_on) : (sortingOrderBy && sortingOrderBy[0] === "full_name") ? (u) => u.full_name.toLowerCase() :  sortingOrderBy && sortingOrderBy], sortingOrder && sortingOrder);

    return (
        <>
            <div className={classes.search}>
                <div style={{display: 'flex'}}>
                    <Title title={isFiltered ? "Filtered Athletes:" : "Total Athletes:"} />
                    <div style={{marginLeft: '-15px'}}>
                        <Title title={loading ? "--" : filteredData && filteredData.length} />
                    </div>
                </div>

                <div style={{display: 'flex'}}>
                    {
                        isFiltered ?
                            <HeaderButton title='Reset Filters' onclick={handleResetFilters} />
                            :
                            ""
                    }
                    <HeaderButton title='Filter' onclick={() => pageChange('filter')} />
                    <InputBase
                        placeholder="Search Player Name"
                        value={searchText}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{'aria-label': 'search'}}
                        onChange={ev => handleSearchText(ev.target.value)}
                    />

                    <SortingPopover
                        icon={
                            <span className={classes.inputInput} style={{marginRight: 0}}>
                                    <span className={classes.ArrowAlignSort}>
                                        <KeyboardArrowUpIcon className={classes.arrowAlignFlex}/>
                                        <KeyboardArrowDownIcon className={classes.arrowAlignFlex}/>
                                    </span>
                                    Sort by
                                </span>
                        }
                    />
                </div>
            </div>
            <Grid container spacing={1}>
                {
                    loading ?
                        <ListLoading/>
                        :
                        filteredData && filteredData.length === 0 ?
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                                {"No players found"}
                            </div>
                            :
                            filteredData && filteredData.sort((a, b) => a.last_name > b.last_name ? 1 : -1).map(list => (
                                <Grid item xs={12} sm={4}>
                                    <Card className={classes.card} key={list._id} onClick={() => handleCardClick(list._id)}>
                                        <div style={{display: 'flex'}}>
                                            <Badge
                                                overlap="circular"
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                }}
                                                badgeContent={
                                                    list.risk === "high" ? (
                                                        <Tooltip title={"High Risk Athlete"}>
                                                            <Avatar className={classes.badgeContent}>
                                                                <ErrorIcon style={{color: "#d32f2f"}}/>
                                                            </Avatar>
                                                        </Tooltip>
                                                    ) : null
                                                }
                                            >
                                                <Avatar src={list.image_url} alt="player" className={classes.avatar}/>
                                            </Badge>
                                            <div style={{width: '100%', marginLeft: '1rem'}}>
                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <span className={classes.fullName}>
                                                        {list.full_name}
                                                    </span>
                                                    <span className={classes.status}
                                                          style={{
                                                              backgroundColor: getBackgroundColor(list.status),
                                                              border: getColor(list.status),
                                                              color: getColor(list.status),
                                                          }}
                                                    >
                                                        {list.status}
                                                    </span>
                                                </div>
                                                <div className={classes.team}>
                                                    {list.team}
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Grid>
                            ))
                }

            </Grid>
        </>
    )
}

let mapStateToProps = (state) => {

    return {
        filterCandidates: state.player.filterCandidates,
        loading: state.ui.player.loadingFilters,
        isFiltered: state.player.isFiltered,
        updateFilter: state.player.updateFilter,
        accountType: state.account.accountType,
        sortingOrderBy: state.player.sortingOrderBy,
        sortingOrder: state.player.playerOrder["sortingOrder"],
    };
};

export default connect(mapStateToProps)(List);