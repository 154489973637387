import runDashedLogo from "../../../assets/RunDashedIcon.svg";

const RunDashedLogo = () => {
    return (
        <div style={{display: 'flex', maxHeight: '100%', height: 400, alignItems: 'center', justifyContent: 'center'}}>
            <img
                src={runDashedLogo}
                alt="logo"
                style={{width: '70%'}}
            />
        </div>
    )
};

export default RunDashedLogo;