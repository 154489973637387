import React from "react";
import MuiTab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import '../../../index.css';

const Tab = withStyles(theme => ({
  root: {
    textTransform: "capitalize",
    fontFamily: theme.fontFamily.heading,
    fontSize: 14,
    color: theme.palette.text.primary,
  }
}))(props => {
  return <MuiTab disableRipple {...props} />;
});

export default Tab;
