import {Box, makeStyles, Paper, Typography} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useContext } from "react";
import { ActionContext } from "../../../contexts";
import { connect } from "react-redux";
import RemainingReportDialog from "./RemainingReportDialog";
import SportsCard from "./SportsCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.white,
    borderLeft: `5px solid ${theme.colors.primaryBlue}`,
    borderRadius: '10px',
    padding: '10px 16px',
  },
  title: {
    fontFamily: theme.fontFamily.heading,
    fontSize: theme.fontSize.md,
    color: theme.colors.primaryBlue,
  },
  subtitle: {
    fontFamily: theme.fontFamily.robotoCondensed,
    fontWeight: 700,
    color: theme.colors.primaryNavy,
  },
  stat: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    fontWeight: 500,
  },
}));

const StatCard = (props) => {
  let classes = useStyles();
  let { title = "", stat = "", loading, selectedId, statusName = "", sport } = props;

  let actions = useContext(ActionContext);
  let { dashboardActions, dialogActions } = actions;

  const handleRedirect = () => {
    if (statusName === "Team Dashboard") {
      dialogActions.openDialog(
        <SportsCard sports={sport} />,
        {
          maxWidth: "xs",
        }
      );
      dashboardActions.getAllLitePlayerInfo("status");
    }

    else if (statusName === "RemainingReports") {
      dialogActions.openDialog(
        <RemainingReportDialog />,
        {
          maxWidth: "xs",
        }
      );

    } else {
      dashboardActions.getAllLitePlayerInfo(statusName);
      dashboardActions.selectCard(statusName);
      dashboardActions.resetDashboardFilter();

    }
  }

  return (
    <>
      <Paper className={classes.root} style={{ backgroundColor: selectedId === statusName ? 'rgb(218, 239, 253, 0.1)' : '' }} elevation={2} onClick={handleRedirect}>
        <Typography variant="h4" className={classes.title}>
          {loading ? <Skeleton animation="wave" /> : stat}
        </Typography>
        <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
          {loading ? <Skeleton animation="wave" /> : <Box className={classes.subtitle}>
            { title }
          </Box>}
        </Typography>
      </Paper>
    </>
  );
};

let mapStateToProps = (state) => {
  return {
    selectedId: state.ui.dashboard.selectedCardId,
  };
};

export default connect(mapStateToProps)(StatCard);

