import React, { useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "../../shared/Editor";
import { makeStyles, Typography } from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
  heading: {
    maxWidth: 480,
    lineHeight: "60px",
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.heading,
  },
  form: {
    paddingTop: theme.spacing(1),
    maxWidth: '100%',
  },
  editor: {
    padding: theme.spacing(0, 1),
    border: "1px solid #f1f1f1",
  },
}));

let CreatePlayerNotes = (props) => {
  let classes = useStyles();
  let [editorState, setEditorState] = useState(EditorState.createEmpty());

  let onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    props.setFieldValue("notes", convertToRaw(editorState.getCurrentContent()));
  };

  return (
    <div>
      <Typography variant="h5" className={classes.heading}>
        Is there more to add?
      </Typography>
      <div className={classes.form}>
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          placeholder="Begin typing..."
          classes={{ editor: classes.editor }}
        />
      </div>
    </div>
  );
};

export default CreatePlayerNotes;
