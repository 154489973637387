import {makeStyles} from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        paddingBottom: '1rem',
    },
    headerBtn: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '13px',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        fontWeight: 700,
        cursor: 'pointer',
        textTransform: 'uppercase',
        textDecoration: 'none',
    },
    noAttributes: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1rem',
        color: theme.palette.error.main,
        fontFamily: theme.fontFamily.heading,
        fontSize: '1.1rem',
    },
    rowAlignment: {
        border: 'solid 1px #f2f2f2',
        borderRadius: 8,
        backgroundColor: theme.palette.common.white,
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8rem 0.5rem',
        width: '100%',
    },
    tableHead: {
        borderBottom: 'solid 1px #f2f2f2',
        backgroundColor: theme.palette.common.white,
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8rem 0.5rem',
        width: '100%',
    },
    fullName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '1.2em',
        whiteSpace: 'nowrap',
        fontWeight: '700',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
        textAlign: 'center',
    },
    dataTableAlignment: {
        fontWeight: '300',
        display: 'flex',
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: 14,
        justifyContent: 'center',
        textTransform: 'capitalize',
    },
    iconColor: {
        color: theme.palette.text.primary,
    },
    form: {
        paddingTop: theme.spacing(2),
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    content: {
        display: "flex",
        justifyContent: "end",
        margin: '2rem 0rem 0rem 0rem'
    },
    btnPrimary: {
        color: theme.palette.common.white,
        fontFamily: theme.fontFamily.robotoCondensed,
        padding: '10px',
        backgroundColor: theme.colors.primaryNavy,
        width: '13rem',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        fontFamily: theme.fontFamily.robotoCondensed,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
        textDecoration: 'none',
        textAlign: 'center',
        fontWeight: 600,
    },
    labelsInput: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    labelItem: {
        display: 'flex',
        flexGrow: 1,
        maxWidth: '50%',
        height: 30,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
        borderRadius: 12,
        backgroundColor: theme.colors.veryLightGrey,
        fontSize: '0.8rem',
        padding: theme.spacing(1),
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    labelItemSpacing: {
        '& + &': {
            marginLeft: theme.spacing(1),
        }
    },
    iconBtn: {
        color: theme.palette.common.white,
        fontFamily: theme.fontFamily.robotoCondensed,
        padding: '10px',
        width: '15rem',
        textAlign: 'center',
        height: 55,
        backgroundColor: theme.colors.primaryNavy,
        marginRight: 10,
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    error: {
        color: theme.palette.error.main,
        marginTop: 15,
    }
}));

export default useStyles;