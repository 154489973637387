import {withStyles} from "@material-ui/core/styles";

export const GlobalCss = withStyles(theme => ({
    '@global': {
        '.MuiFormLabel-root': {
            color: theme.palette.text.disabled,
            fontFamily: theme.fontFamily.robotoCondensed,
        },
        '.MuiTypography-h6': {
            color: theme.palette.text.primary,
            fontFamily: theme.fontFamily.heading,
        },
        '.MuiButton-root': {
            color: theme.palette.text.secondary,
        },
        '.MuiButton-textPrimary': {
            color: theme.palette.text.primary,
        },
        '.MuiTypography-colorTextSecondary': {
            color: theme.palette.text.primary,
            paddingTop: theme.spacing(1),
            fontWeight: 600,
        },
        '.MuiTypography-body1': {
            fontFamily: theme.fontFamily.robotoCondensed,
        },
        '.MuiStepIcon-root.MuiStepIcon-active': {
          color: theme.palette.text.primary,
        },
        '.MuiFormHelperText-root': {
            color: theme.palette.error.main,
        },
        '.MuiStepIcon-root.MuiStepIcon-completed': {
            color: theme.palette.text.primary,
        },
    },
}))(() => null);
