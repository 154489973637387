import React, {useState, useContext, useEffect} from 'react'
import { Button, Card, makeStyles, Avatar, fade, Badge, Tooltip, Grid, InputBase, Slide } from "@material-ui/core"
import { connect } from "react-redux";
import ErrorIcon from "@material-ui/icons/ErrorRounded";
import ListLoading from "./ListLoading";
import { ActionContext } from "../../../contexts";
import PlayerDetails from "../components/PlayerDetails";

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="left"
            ref={ref}
            timeout={{ enter: 1000, exit: 1000 }}
            {...props}
        />
    );
});

let useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: `calc(100vh - 50px)`,
        zIndex: theme.zIndex.appBar,
        backgroundColor: theme.colors.veryLightGrey,
    },
    container: {
        padding: 16,
        width: '100%',
        marginLeft: -30,
    },
    FullName: {
        color: theme.colors.primaryNavy,
        fontFamily: theme.fontFamily.heading,
    },
    Team: {
        color: theme.colors.darkBlue60Percent,
        fontSize: '12px',
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    Status: {
        fontSize: '11px',
        padding: '0.1rem 0.7rem',
        borderRadius: '0.9rem',
        fontWeight: 500,
    },
    avatar: {
        backgroundColor: theme.colors.primaryNavy,
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    secondaryAction: {
        display: "flex",
        flexDirection: "column",
    },
    badgeContent: {
        width: 18,
        height: 18,
        backgroundColor: theme.palette.background.paper,
        border: `2px solid #fff`,
    },
    card: {
        flexGrow: 0,
        padding: '14px',
        borderRadius: '9px',
        boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.06)',
        backgroundColor: theme.colors.white,
        borderLeft: `5px solid ${theme.palette.primary.main}`,
        '&:hover': {
            cursor: 'pointer',
        },
        color: theme.colors.primaryNavy,
        minHeight: 85,
    },
    search: {
        justifyContent: 'space-between',
        display: 'flex',
        position: 'relative',
        marginBottom: '14px',
        marginRight: '0px',
        marginTop: '14px',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        marginLeft: 0,
        width: '100%',
        padding: '1rem 0px'
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: '8px 15px',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        paddingLeft: '10px',
        maxWidth: 330 ?? '100%',
        borderRadius: 5,
        color: theme.colors.darkBlue60Percent,
        backgroundColor: theme.colors.veryLightGrey,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontWeight: 500,
        border: 'none',
    },
    AvatarCellDesign: {
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        fontSize: '12px',
        height: '32px',
        width: '32px',
        boxShadow: '3px 3px 8px 0px #cdcdcd',
        color: '#fff',
        fontWeight: '700'
    },
    btnPrimary: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem',
        fontSize: '13px',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.colors.primaryNavy,
        fontWeight: 700,
        cursor: 'pointer',
        textTransform: 'uppercase'
    },
    btnSecondary: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem',
        fontSize: '13px',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.secondary.main,
        fontWeight: 700,
        cursor: 'pointer',
        textTransform: 'uppercase',
    },
    title: {
        fontSize: theme.fontSize.md,
        fontFamily: theme.fontFamily.heading,
        color: theme.colors.primaryNavy,
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
    }
}));

function List(props) {
    let classes = useStyles();
    const { loading, allCoaches, isFilteredApply, isFiltered, updateFilter } = props;
    let actions = useContext(ActionContext);
    let { coachActions, dialogActions } = actions;
    const [searchText, setSearchText] = useState(undefined);

    useEffect(() => {
        coachActions.getAllFilterCoaches(updateFilter)
    }, [])

    function handleSearchText(v) {
        setSearchText(v);
    }

    const handleCardClick = (coachId) => {
        coachActions.getCoach(coachId);
        coachActions.selectCoach(coachId);

        dialogActions.openDialog(<PlayerDetails coachId={coachId} />, {
            fullScreen: true,
            TransitionComponent: Transition,
        });
    };

    const handleResetFilters = () => {
        let model = {
            conference: [],
            sport: [],
            unit: [],
            coachType: [],
            conferenceType: []
        }

        coachActions.getAllFilterCoaches(model)
        coachActions.isCoachFiltered(false)
        coachActions.resetCoachFilter()
        isFilteredApply(false)
    }

    const filteredData = searchText ? allCoaches && allCoaches.filter(v => v.full_name.toLowerCase().includes(searchText.toLowerCase())) : allCoaches && allCoaches

    return (
        <div className={classes.container}>
            <div className={classes.search}>
                <div className={classes.title}>
                    <label
                        style={{marginRight: 5}}>{isFiltered ? "Filtered Coaches:" : "Total Coaches:"} </label><b>{loading ? "--" : filteredData && filteredData.length}</b>
                </div>

                <div style={{display: 'flex'}}>
                    {
                        isFiltered ?
                            <Button className={classes.btnSecondary} onClick={handleResetFilters}>Reset Filters</Button>
                            :
                            ""
                    }
                    {/* todo: fix candidate risks on the backend, update filter functionality on the frontend */}
                    {/*<HeaderButton title='Filter' onclick={() => pageChange('filter')}/>*/}
                    <InputBase
                        placeholder="Search Coach Name"
                        value={searchText}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{'aria-label': 'search'}}
                        onChange={ev => handleSearchText(ev.target.value)}
                    />
                </div>
            </div>
            <Grid container spacing={1}>
                {
                    loading ?
                        <ListLoading/>
                        :
                        filteredData && filteredData.length === 0 ?
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '10px',
                                color: '#e61e21'
                            }}>
                                {"No coaches found"}
                            </div>
                            :
                            filteredData && filteredData.sort((a, b) => a.last_name > b.last_name ? 1 : -1).map(list => (
                                <Grid item xs={12} sm={4}>
                                    <Card className={classes.card} onClick={() => handleCardClick(list._id)}>
                                        <div style={{display: 'flex'}}>
                                            <Badge
                                                overlap="circular"
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                }}
                                                badgeContent={
                                                    list.risk === "high" ? (
                                                        <Tooltip title={"High Risk Athlete"}>
                                                            <Avatar className={classes.badgeContent}>
                                                                <ErrorIcon style={{color: "#d32f2f"}}/>
                                                            </Avatar>
                                                        </Tooltip>
                                                    ) : null
                                                }
                                            >
                                                <Avatar src={list.image_url} alt="player" className={classes.avatar}/>
                                            </Badge>
                                            <div style={{width: '100%', marginLeft: '1rem'}}>
                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <span className={classes.FullName}>{list.full_name}</span>
                                                    <span className={classes.Status}
                                                          style={{
                                                              background: list.status === 'Completed' ? '#e0f5ea' : list.status === 'Approval Pending' ? '#cfe3f2' : list.status === 'Rejected' ? '#EBE2E9' : list.status === 'Processing' ? '#fbf3d9' : list.status === 'Psych Review Pending' ? '#f2f2f2' : list.status === 'Consent Pending' ? '#cccccc' : list.status === 'Error' ? '#fddfdf' : list.status === 'Base Report Completed' ? '#BDBDBE' : '',
                                                              border: list.status === 'Completed' ? '1px solid #e0f5ea' : list.status === 'Approval Pending' ? '1px solid #cfe3f2' : list.status === 'Rejected' ? '1px solid #EBE2E9' : list.status === 'Processing' ? '#fbf3d9' : list.status === 'Psych Review Pending' ? '1px solid #f2f2f2' : list.status === 'Consent Pending' ? '1px solid #cccccc' : list.status === 'Error' ? '1px solid #fddfdf' : list.status === 'Base Report Completed' ? '1px solid #BDBDBE' : '1px solid #000',
                                                              color: list.status === 'Completed' ? '#00ae4d' : list.status === 'Approval Pending' ? '#1075bf' : list.status === 'Rejected' ? '#BC5353' : list.status === 'Processing' ? '#e7b618' : list.status === 'Psych Review Pending' ? '#666666' : list.status === 'Consent Pending' ? '#000000' : list.status === 'Error' ? '#f33a3a' : list.status === 'Base Report Completed' ? '#1A73E8' : ''
                                                          }}
                                                    >{list.status}</span>
                                                </div>
                                                <div className={classes.Team}>
                                                    {list.team}
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Grid>
                            ))
                }

            </Grid>
        </div>
    )
}

let mapStateToProps = (state) => {

    return {
        allCoaches: state.coach.filterCoaches,
        loading: state.ui.coach.loadingCoachFilters,
        isFiltered: state.coach.isFilterCoaches,
        updateFilter: state.coach.updateCoachFilter,
    };


};

export default connect(mapStateToProps)(List);